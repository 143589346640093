<style>
.hidden_header {
	display: none;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row>
			<b-col cols="12" md="12">
				<b-card no-body class="card-border-blue-light">
					<div v-if="User" :class="fillClassContainer" class="px-0">
						<div class="container-header-card-collapsible cursor-pointer w-100" @click="handleClickHeader">
							<div class="title">
								<div class="label w-90">
									<h4>{{ FormMSG(1, 'Profile') }}</h4>
								</div>
								<div class="icon w-10">
									<component v-if="!configAccordion.isVisible" :is="getIconLucide('ChevronDown')" color="#06263E" :size="22" />
									<component v-if="configAccordion.isVisible" :is="getIconLucide('ChevronUp')" color="#06263E" :size="22" />
								</div>
							</div>
						</div>
						<b-collapse :visible="configAccordion.isVisible" :class="$screen.width <= 576 ? 'mt-3' : ''">
							<div class="content-card-collapsible">
								<profile
									:selectedUserData="selectedUserData"
									:data="User"
									@change="handleUserEntryLevelChange"
									:disabled="true"
									:handleAccessLevel="(item, value, data, screen) => this.$props.handleAccessLevel(item, value, data, screen)"
								/>
								<b-row class="d-flex justify-content-center pt-4">
									<b-col sm="12" md="4" lg="4" xl="4">
										<b-button block @click="updateUser()" variant="secondary" class="w-100"> {{ this.FormMSG(50, 'Save user') }}</b-button>
									</b-col>
								</b-row>
								<template slot="footer" v-if="isDev">
									<b-button v-on:click="becomeUser(userId)">Impersonate</b-button>
								</template>
							</div>
						</b-collapse>
					</div>
				</b-card>
				<!-- {{ contracts }} :disableSave="warning.length > 0"-->
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="12" md="12" v-if="this.isForProject()">
				<contract
					v-for="item in contracts"
					:disableDelete="contracts.length === 1"
					:key="item.id"
					:contractInput="item"
					:accordion="accordion"
					:hide-send-package-btn="true"
					:fill-class-container="'container-layout'"
					:user-id="userId"
					:show-pay-code="showPayCode"
					@isLoading="isLoading = $event"
					@successModal="successModal = $event"
					@erreur="erreur = $event"
					@contract:show-body="handleContractShowBody"
				/>
				<b-row class="d-flex justify-content-center" :class="`${displayAddBtn ? 'pb-2' : ''}`">
					<b-col sm="12" md="4" lg="4" xl="4" :style="`display: ${displayAddBtn ? 'block' : 'none'} !important;`">
						<b-button ref="new-contract" size="lg" @click="addContract(userId)" block>
							{{ this.FormMSG(51, 'New contract') }}
						</b-button>
					</b-col>
				</b-row>
			</b-col>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="FormMSG(60, 'Success!')"
				class="modal-success"
				v-model="successModal"
				@ok="successModal = false"
				ok-variant="success"
				ok-only
			>
				{{ this.FormMSG(61, 'Your information has been saved.') }}
			</b-modal>
			<loading :active.sync="isLoading" :is-full-page="true" color="#00A09C"></loading>
		</b-row>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import { DateTime, Interval } from 'luxon';
import contract from './contract';
import languageMessages from '@/mixins/languageMessages';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { addEncodingValidation } from '@/cruds/users.crud';
//import AddressForm from "~/components/Address/Forms/Address.vue";
import AddressForm from '@/components/Forms/Address';
import verte from 'verte';
import 'verte/dist/verte.css';
import GlobalMixin from '@/mixins/global.mixin';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { updateUserProjectReference } from '@/cruds/users.crud';
import * as icons from 'lucide-vue';
import Profile from '@/components/MemoDeal/Profile';
import { isNil } from '@/shared/utils';
import { contracts } from '@/cruds/contract.crud';
// import { getCategoryByFunctionId } from '@/cruds/categorie.crud'
import { noTilde } from '~utils';

const query_user = gql`
	query ($userid: ID!) {
		User(userID: $userid) {
			id
			firstName
			name
			email
			iban
			birthDate
			language
			socSecNumber
			civilStatus
			placeOfBirth
			placeOfBirthZip
			nationalRegistryNumber
			bic
			phone
			picture
			nationality
			companyNumber
			isDev
			isTranslator
			currencySymbol
			distanceUnit
			appLanguage
			department
			departmentName
			function
			functionName
			contractStartDate
			contractEndDate
			gender
			driverLicense
			driverLicenseNumber
			allergies
			spouseName
			spouseFirstName
			spouseBirthDate
			spouseProfession
			spouseHasSalary
			numberOfDependentChild
			numberOfDisabledChild
			numberOfDependentPerson
			numberOfDisabledPerson
			personToContact
			customFunctionName
			personToContactPhone
			disabled
			color
			showWizard
			addressID
			address {
				street
				number
				zip
				city
				box
				state
				country
				countryCode
				countryKey
			}
			reference
			idNumber
			shoeSize
			tShirtSize
			showMyEmailInCrew
			showMyPhoneInCrew
			idName
			pictureId
			pictureIdVerso
			pictureDriverLicense
			pictureDriverLicenseVerso
			signatureId
			taxesPercentage
			otherStudyLevel
			size
			pantSize
			jacketSize
			vehicule {
				id
				name
				brand
				model
				numberPlate
				type
				subType
				additionalSubType
				energy
				reference
				kgCoTwoPerKm
				taxHorsepower
				grayCardXid
				grayCardVersoXid
			}
			pictureRib
			maidenName
			title
			birthCountry
			congesSpectacle
			bankAccountOwnerName
			bankDetailXid
			taxCountry
			postalAddressID
			postalAddress {
				id
				street
				number
				box
				zip
				state
				city
				country
				countryCode
				countryKey
				other
				lat
				lng
			}
			socialSecurityCardXid
			insuranceCertificateXid
			socialSecurityCenter
			socialSecurityCenterAddressId
			lastMedicalVisit
			medicalVisitValidity
			medicalVisitDocumentXid
			disabilityNotification
			disabilityType
			disabilityStatus
			invalidity
			rqth
			invalidityPourcentage
			retired
			retirementDate
			trainLoyaltyCard
			trainDiscountCard
			flyingLoyaltyCard
			otherAirlineCard
			residencePermitNumber
			residencePermitEndValidity
			residentPermitXid
			workCardXid
			pseudonym
			professionalAssociation
			session1A
			session1B
			session2A
			session2B
			session3A
			session3B
			session4A
			session4B
			session5A
			session5B
			session6A
			session6B
			session7A
			session7B
			session8A
			session8B
		}
	}
`;

const mutation_updUser = gql`
	mutation ($UserId: ID!, $UpdatedUser: UserInput!) {
		UpdUser(UserId: $UserId, UpdatedUser: $UpdatedUser) {
			id
		}
	}
`;

const mutation_signInAs = gql`
	mutation ($userid: ID!) {
		signInAs(userID: $userid)
	}
`;

const mutation_newContract = gql`
	mutation ($userid: ID!) {
		NewContract(UserId: $userid) {
			id
		}
	}
`;

export default {
	name: 'User',
	mixins: [languageMessages, GlobalMixin, isSingleProjectMixin],
	props: {
		selectedUserData: {
			type: Object,
			required: false,
			default: () => {}
		},
		caption: {
			type: String,
			default: 'User id'
		},
		userId: {
			type: String
		},
		fillClassContainer: {
			type: String,
			required: false,
			default: 'container-layout' // default empty, or 'container-layout'
		},
		displayAddBtn: {
			type: Boolean,
			required: false,
			default: true
		},
		handleAccessLevel: {
			type: Function,
			required: false
		}
	},
	components: {
		Loading,
		contract,
		AddressForm,
		verte,
		Profile
	},
	apollo: {
		User: {
			query: query_user,
			variables() {
				return {
					userid: parseInt(this.userId, 10)
				};
			},
			error(err) {
				this.erreur = err;
			},
			fetchPolicy: 'no-cache'
		}
	},
	data: () => {
		return {
			searchAddressFromVat: null,
			googleAutoCompleteRestrictedCountry: null,
			erreur: {},
			warning: '',
			User: null,
			contracts: [],
			successModal: false,
			isLoading: false,
			accordion: {},
			configAccordion: {
				isVisible: true
			},
			userEntryData: {},
			showPayCode: false
		};
	},
	async created() {
		this.contracts = await contracts(+this.userId).catch((error) => {
			console.log({ error });
		});
		this.showPayCode = store.getCurrentProjectConfig().payrollInterface === 0 ? false : true;
	},
	watch: {
		// whenever question changes, this function will run
		contracts: {
			handler: function (newContracts) {
				for (let i = 0; i < newContracts.length; i++) {
					for (let j = i + 1; j < newContracts.length; j++) {
						const start1 = DateTime.fromISO(newContracts[i].startDate);
						const end1 = DateTime.fromISO(newContracts[i].endDate);
						const start2 = DateTime.fromISO(newContracts[j].startDate);
						const end2 = DateTime.fromISO(newContracts[j].endDate);

						const interval1 = Interval.fromDateTimes(start1, end1);
						const interval2 = Interval.fromDateTimes(start2, end2);

						if (interval1.overlaps(interval2)) {
							this.warning = this.FormMSG(70, 'Some of the contract dates overlap');
							return;
						} else {
							this.warning = '';
						}
					}
				}
			},
			deep: true,
			immediate: true
		},
		'store.state.myProject.payrollInterface': {
			handler(value) {
				if (!isNil(value)) {
					this.showPayCode = value === 0 ? false : true;
				}
			}
		}
	},
	computed: {
		useTask() {
			return store.getCurrentProjectConfig().useTask;
		},
		optionsForCountryCode() {
			return this.FormMenu(9);
		},
		optionsForCivilStatus() {
			return this.FormMenu(1001);
		},
		optionsForLanguages() {
			let menus = this.FormMenu(1);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		imageName() {
			if (this.User.picture && this.User.picture.length > 0) {
				var strImg = process.env.VUE_APP_GQL + '/images/' + this.User.picture;
				//  console.log("pictPath:",strImg);
				return strImg;
			} else {
				return 'img/brand/M.JPG';
			}
		},
		isDev() {
			return store.isDev();
		}
	},
	methods: {
		handleUserEntryLevelChange(data) {
			this.userEntryData = Object.assign({}, data);
		},
		handleContractShowBody(payload) {
			this.accordion = payload;
		},
		handleAdress(address) {
			this.User.address = address;
		},
		// getCountryIsoFromKey(key = 16965) {
		//     const country = getObjectFromValue(
		//         this.optionsForCountryCode,
		//         'value',
		//         key
		//     )
		//     return country.text.toLowerCase()
		// },
		isForProject() {
			//console.log("in isForProject");
			if (this.$route.path.includes('project')) {
				return true;
			} else {
				return false;
			}
		},
		updateBirthDate: function ($event) {
			if (this.User.birthDate.startsWith('T')) {
				this.User.birthDate = $event + this.User.birthDate;
			} else {
				this.User.birthDate = $event + this.User.birthDate.substring(10);
			}
		},
		isDisabled() {
			return false;
		},
		becomeUser: function (id) {
			var userid = parseInt(id, 10);
			this.$apollo
				.mutate({
					mutation: mutation_signInAs,
					variables: {
						userid
					}
				})
				.then(async (result) => {
					const response = await store.onNewToken(this.$apollo.provider.defaultClient, result.data.signInAs);
					if (response) {
						//this.$router.push("/my-dashboard")
						this.$router.go();
					}
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		addContract: function (id) {
			const userid = parseInt(id, 10);
			this.$apollo
				.mutate({
					mutation: mutation_newContract,
					variables: {
						userid
					}
				})
				.then(async (result) => {
					// console.log(result)
					// this.$apollo.queries.Contracts.refetch(); // https://github.com/Akryum/vue-apollo/issues/376
					this.contracts = await contracts(+this.userId).catch((error) => {
						console.log({ error });
					});
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async updateUser() {
			await addEncodingValidation(
				parseInt(store.projectID(), 10),
				this.userEntryData.userId,
				this.userEntryData.timeSheets,
				this.userEntryData.department,
				this.userEntryData.expenses,
				this.userEntryData.po
			);
			let UpdatedUser = {
				firstName: this.User.firstName,
				name: this.User.name,
				email: this.User.email,
				phone: this.User.phone,
				iban: this.User.iban,
				bic: this.User.bic,
				language: this.User.language,
				socSecNumber: this.User.socSecNumber,
				civilStatus: this.User.civilStatus,
				nationalRegistryNumber: this.User.nationalRegistryNumber,
				nationality: this.User.nationality,
				birthDate: this.User.birthDate,
				companyNumber: this.User.companyNumber,
				picture: this.User.picture,
				placeOfBirth: this.User.placeOfBirth,
				placeOfBirthZip: this.User.placeOfBirthZip,
				gender: parseInt(this.User.gender),
				driverLicense: this.User.driverLicense,
				driverLicenseNumber: this.User.driverLicenseNumber,
				allergies: this.User.allergies,
				spouseName: this.User.spouseName,
				spouseFirstName: this.User.spouseFirstName,
				spouseBirthDate: this.User.spouseBirthDate,
				spouseProfession: this.User.spouseProfession,
				spouseHasSalary: this.User.spouseHasSalary,
				numberOfDependentChild: parseInt(this.User.numberOfDependentChild),
				numberOfDisabledChild: parseInt(this.User.numberOfDisabledChild),
				numberOfDependentPerson: parseInt(this.User.numberOfDependentPerson),
				numberOfDisabledPerson: parseInt(this.User.numberOfDisabledPerson),
				personToContact: this.User.personToContact,
				personToContactPhone: this.User.personToContactPhone,
				disabled: this.User.disabled,
				color: this.User.color,
				idNumber: this.User.idNumber,
				shoeSize: this.User.shoeSize,
				tShirtSize: this.User.tShirtSize,
				showMyEmailInCrew: this.User.showMyEmailInCrew,
				showMyPhoneInCrew: this.User.showMyPhoneInCrew,
				address: {
					street: this.User.address.street,
					number: this.User.address.number,
					zip: this.User.address.zip,
					city: this.User.address.city,
					box: this.User.address.box,
					state: this.User.address.state,
					country: this.User.address.country,
					countryKey: this.User.address.countryKey,
					lat: this.User.address.lat,
					lng: this.User.address.lng
				},
				idName: this.User.idName,
				taxesPercentage: parseInt(this.User.taxesPercentage, 10),
				vehicule: {
					name: this.User.vehicule.name,
					model: this.User.vehicule.model,
					brand: this.User.vehicule.brand,
					numberPlate: this.User.vehicule.numberPlate,
					type: +this.User.vehicule.type,
					energy: +this.User.vehicule.energy,
					kgCoTwoPerKm: parseFloat(this.User.vehicule.kgCoTwoPerKm)
				},
				pictureRib: this.User.pictureRib,
				maidenName: this.User.maidenName,
				title: this.User.title,
				birthCountry: this.User.birthCountry,
				congesSpectacle: this.User.congesSpectacle,
				bankAccountOwnerName: this.User.bankAccountOwnerName,
				bankDetailXid: this.User.bankDetailXid,
				taxCountry: this.User.taxCountry,
				postalAddressID: this.User.postalAddressID,
				postalAddress: {
					id: this.User.postalAddress.id,
					street: this.User.postalAddress.street,
					number: this.User.postalAddress.number,
					box: this.User.postalAddress.box,
					zip: this.User.postalAddress.zip,
					state: this.User.postalAddress.state,
					city: this.User.postalAddress.city,
					country: this.User.postalAddress.country,
					countryCode: this.User.postalAddress.countryCode,
					countryKey: this.User.postalAddress.countryKey,
					other: this.User.postalAddress.other,
					lat: this.User.postalAddress.lat,
					lng: this.User.postalAddress.lng
				},
				socialSecurityCardXid: this.User.socialSecurityCardXid,
				insuranceCertificateXid: this.User.insuranceCertificateXid,
				socialSecurityCenter: this.User.socialSecurityCenter,
				socialSecurityCenterAddressId: this.User.socialSecurityCenterAddressId,
				lastMedicalVisit: this.User.lastMedicalVisit,
				medicalVisitValidity: this.User.medicalVisitValidity,
				medicalVisitDocumentXid: this.User.medicalVisitDocumentXid,
				disabilityNotification: this.User.disabilityNotification,
				disabilityType: this.User.disabilityType,
				disabilityStatus: this.User.disabilityStatus,
				invalidity: this.User.invalidity,
				rqth: this.User.rqth,
				invalidityPourcentage: this.User.invalidityPourcentage,
				retired: this.User.retired,
				retirementDate: this.User.retirementDate,
				trainLoyaltyCard: this.User.trainLoyaltyCard,
				trainDiscountCard: this.User.trainDiscountCard,
				flyingLoyaltyCard: this.User.flyingLoyaltyCard,
				otherAirlineCard: this.User.otherAirlineCard,
				residencePermitNumber: this.User.residencePermitNumber,
				residencePermitEndValidity: this.User.residencePermitEndValidity,
				residentPermitXid: this.User.residentPermitXid,
				workCardXid: this.User.workCardXid,
				pseudonym: this.User.pseudonym,
				professionalAssociation: this.User.professionalAssociation,
				session1A: this.User.session1A,
				session1B: this.User.session1B,
				session2A: this.User.session2A,
				session2B: this.User.session2B,
				session3A: this.User.session3A,
				session3B: this.User.session3B,
				session4A: this.User.session4A,
				session4B: this.User.session4B,
				session5A: this.User.session5A,
				session5B: this.User.session5B,
				session6A: this.User.session6A,
				session6B: this.User.session6B,
				session7A: this.User.session7A,
				session7B: this.User.session7B,
				session8A: this.User.session8A,
				session8B: this.User.session8B,
				customFunctionName: this.User.customFunctionName
			};
			this.$apollo
				.mutate({
					mutation: mutation_updUser,
					variables: {
						UserId: parseInt(this.User.id, 10),
						UpdatedUser
					}
				})
				.then(async (result) => {
					await updateUserProjectReference({ userId: parseInt(this.User.id, 10), reference: this.User.reference })
						.then((bool) => {
							if (bool) {
								// console.log(result)
								this.createToastForMobile(this.FormMSG(27, 'Success'), this.FormMSG(26, 'User data updated successfully'), '', 'success');
								// this.finishFormSubmission();
							}
						})
						.catch((e) => {
							console.log({ e });
						});
				})
				.catch((e) => {
					console.log({ e });
					// this.isLoading = false;
					// this.erreur = error
				});
		},
		finishFormSubmission() {
			this.$emit('closeForm');
		},
		getIconLucide(name) {
			return icons[name];
		},
		handleClickHeader() {
			this.configAccordion.isVisible = !this.configAccordion.isVisible;
		}
	}
};
</script>
